
(function(){
   'use strict';
	
   angular
   .module('vdsr')
   .filter('enter2fCodeTitle', [function() {
      return filter_;
   }]);
   
   /**
    * @param {TFMethodName} methodName 
    * @param {boolean} lowerCased
    */
   function filter_(methodName, lowerCased) {
      let txt = [
         `Please enter the six-digit code`, 
         methodName !== "app" ? 'sent to' : 'from', `your`, methodName.replace('sms', 'phone')
      ].join(' ');
      return lowerCased ? txt.toLocaleLowerCase() : txt;
   }
   
}());


